import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { Close as CloseIcon } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import ConfirmDialog from 'components/ConfirmDialog'
import { State } from 'containers/Dashboard/types'
import useStates from 'hooks/useStates'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import './styles.scss'

interface AddNutritionStateDialogProps {
  open: boolean
  handleClose: () => void
}

const AddNutritionStateDialog: React.FC<AddNutritionStateDialogProps> = ({
  open,
  handleClose,
}) => {
  const { states, eligibleNutritionStates, handleUpdateStateEligibility } =
    useStates()
  const [values, setValues] = useState<State[]>(eligibleNutritionStates)
  const [confirmAddText, setConfirmAddText] = useState<string | null>(null)

  const newStates = useMemo(
    () =>
      values.filter((state) => eligibleNutritionStates.indexOf(state) === -1),
    [values, eligibleNutritionStates]
  )

  useEffect(() => {
    setValues(eligibleNutritionStates)
  }, [eligibleNutritionStates, setValues])

  const handleChange = useCallback(
    (_: React.ChangeEvent<unknown>, value: State[]) => {
      setValues([
        ...eligibleNutritionStates,
        ...value.filter(
          (state) => eligibleNutritionStates.indexOf(state) === -1
        ),
      ])
    },
    [eligibleNutritionStates]
  )

  const openConfirmDialog = () =>
    setConfirmAddText(
      `state${newStates.length > 1 ? 's' : ''} ${newStates
        .map((s) => s.fullName)
        .join(', ')}`
    )

  const closeConfirmDialog = () => setConfirmAddText(null)

  const handleCancel = () => {
    setValues(eligibleNutritionStates)
    handleClose()
  }

  const handleSave = () => {
    newStates.forEach((s) =>
      handleUpdateStateEligibility({ state: s, eligibleNutrition: true })
    )
    // TODO: add success confirmation
    handleClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="add-state-dialog"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4">Add Nutrition State</Typography>
        <div className="add-state-list">
          <Autocomplete
            multiple
            autoHighlight
            options={states}
            getOptionLabel={(option) => option.fullName}
            getOptionSelected={(option, value) => option.code === value.code}
            defaultValue={values}
            onChange={handleChange}
            filterSelectedOptions
            renderTags={(tagValue) =>
              tagValue.map((state) => (
                <Chip
                  key={state.code}
                  label={state.fullName}
                  className="state-chip"
                  disabled={eligibleNutritionStates.includes(state)}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="States"
                placeholder="Enter State name..."
              />
            )}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={openConfirmDialog} disabled={newStates.length < 1}>
          Save
        </Button>
      </DialogActions>
      <ConfirmDialog
        key={values.map((v) => v.code).join(',')}
        open={confirmAddText !== null}
        text={`Are you sure you want to add ${confirmAddText}?`}
        titleText={`Confirm Add Eligible Nutrition State${
          newStates.length > 1 ? 's' : ''
        }?`}
        helperText={'Note: this action cannot be undone via the admin panel'}
        onConfirm={handleSave}
        handleClose={closeConfirmDialog}
      />
    </Dialog>
  )
}

export default memo(AddNutritionStateDialog)
